import React from "react";
import "./assets/css/index.css";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import Loader from "./components/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const loadAuthenticatedApp = () => import("./authenticated-app");
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import("./unauthenticated-app"));

const App = () => {
  // const context = useContext(AuthContext);
  const { Consumer } = AuthContext;
  return (
    <React.Suspense
      fallback={
        <div className="w-full absolute h-full flex items-center justify-center">
          <Loader classNames="primary-spinner w-12 h-12" />
        </div>
      }
    >
      <AuthProvider>
        <Consumer>
          {(value) => (
            <div>
              {/* {value !== undefined && value.isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp /> } */}
              {value !== undefined && value.isAuthenticated ? (
                <AuthenticatedApp />
              ) : (
                <UnauthenticatedApp />
              )}
              {/* <AuthenticatedApp /> */}
            </div>
          )}
        </Consumer>
      </AuthProvider>
      <ToastContainer autoClose={5000} draggable />
    </React.Suspense>
  );
};

export default App;
