import * as React from "react";
import { login as authLogin, logout as authLogout } from "../api/auth";
import { LoginForm } from "../api/interfaces";

interface ResponseType {
  status: string;
  data: any;
  message: string;
}

export interface User {
  id?: number;
  fullname?: string;
  email?: string;
  phoneNumber?: string;
  referralCode?: string;
  phoneVerified?: boolean;
  emailVerified?: boolean;
}

interface AuthContextValue {
  isAuthenticated: boolean;
  login?: (form: LoginForm) => Promise<ResponseType>;
  logout?: () => Promise<boolean>;
  accessToken?: string | undefined;
}

interface AuthProviderProps {
  children: any;
}

const AuthContext = React.createContext<AuthContextValue>({
  isAuthenticated: false,
});

async function getUserToken() {
  try {
    const token = await localStorage.getItem("jtb_access_token");
    return token ? token : null;
  } catch (error) {
    return null;
  }
}

function AuthProvider(props: AuthProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<string | undefined>(undefined);
  const [attemptFinished, setAttemptFinished] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(false);
  const [isSettled, setIsSettled] = React.useState<boolean>(false);
  const [accessToken, setToken] = React.useState<string>();

  const { children } = props;
  React.useEffect(() => {
    getUserToken().then((value) => {
      if (value) {
        setToken(value);
        setIsAuthenticated(true);
      }

      if (!value) {
        setIsSettled(true);
        setIsAuthenticated(false);
      }
    });
  }, [reload]);

  React.useLayoutEffect(() => {
    if (isSettled) {
      setAttemptFinished(true);
    }
  }, [isSettled]);

  function handleResponse(response: ResponseType): ResponseType {
    // const { status, data, message } = response;

    // if (response.status === "401") {
    //   alert("problem");
    // }
    if (response.status === "200") {
      setToken(response.data["access-token"]);
      window.localStorage.setItem("jtb-firstname", response.data["firstname"]);
      window.localStorage.setItem("jtb-lastname", response.data["lastname"]);
      window.localStorage.setItem("roles", response.data.roles[0]);

      setReload(!reload);
      setIsAuthenticated(true);
      // setTimeout(() => {}, 600000);
    }

    return response;
  }

  const login = (form: LoginForm): Promise<ResponseType> =>
    authLogin(form).then((response) => handleResponse(response));
  const logout = (): Promise<boolean> =>
    authLogout().then((response) => {
      setIsAuthenticated(false);
      setReload(!reload);
      return response;
    });

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        accessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
