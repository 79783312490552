import React from 'react';

interface LoaderProps {classNames: string; style?: React.CSSProperties}

function Loader({classNames, style}: LoaderProps) {
    return (
        <div className={`spin-loader ${classNames}`} style={{...style}}></div>
    )
}

export default Loader